// import * as React from 'react'
// import Layout from '../components/layout'
// import Modal from '../components/modal'
// import SEO from '../components/seo'
// import {Link} from 'gatsby'

// import products from '../../content/products.json'

// export default function ProductsTemplate() {
//   const affiliateQuery = '?affiliates=76603'

//   return (
//     <Layout>
//       <SEO
//         title="Aprende Francés: Los verbos"
//         description="Descubre artículos informativos sobre la lengua y cultura francesa. Aprende francés con consejos y recursos útiles en el blog de Lemot."
//         hasJsonLd
//       />
//       <div className="wrapper">
//         <Modal />
//         <section className="section is-medium">
//           <h1 className="title has-text-centered">
//             Productos Educativos para Aprender Francés: Recursos para Todos los
//             Niveles
//           </h1>
//         </section>
//         <section className="section is-small">
//           <div
//             className="columns is-multiline"
//             style={{marginBottom: '1.5rem'}}
//           >
//             {products.map(product => {
//               return (
//                 <div
//                   className="column is-one-third"
//                   key={product.slug}
//                   style={{marginBottom: '2rem'}}
//                 >
//                   <Link to={`${product.url}${affiliateQuery}`}>
//                     <div
//                       style={{
//                         minHeight: 150,
//                       }}
//                     >
//                       <h3
//                         style={{height: 45}}
//                         className="title is-5 has-text-centered"
//                       >
//                         {product?.title}
//                       </h3>
//                       <div className="card-image">
//                         <figure className="image">
//                           <img
//                             src={`/assets/products/${product.fileName}`}
//                             alt={product.fileName}
//                           />
//                         </figure>
//                       </div>
//                       <div className="card-content">
//                         <div className="has-text-weight-bold top-bottom-overflow-fade has-text-centered">
//                           <span>{product.description}</span>
//                         </div>
//                       </div>
//                     </div>
//                   </Link>
//                 </div>
//               )
//             })}
//           </div>
//         </section>
//       </div>
//     </Layout>
//   )
// }

import * as React from 'react'
import Layout from '../components/layout'
import Modal from '../components/modal'
import SEO from '../components/seo'
import {Link} from 'gatsby'

import products from '../../content/products.json'

export default function ProductsTemplate() {
  const affiliateQuery = '?affiliates=76603'

  return (
    <Layout>
      <SEO
        title="Aprende Francés: Productos Educativos"
        description="Descubre productos educativos recomendados para aprender francés. Encuentra recursos efectivos para mejorar tu francés en todos los niveles."
        hasJsonLd
      />
      <div className="wrapper">
        <Modal />
        <section className="section is-medium">
          <h1 className="title has-text-centered">
            Productos Educativos para Aprender Francés: Recursos para Todos los
            Niveles
          </h1>
          <p className="subtitle has-text-centered">
            Encuentra los mejores productos para aprender francés y mejorar tus
            habilidades lingüísticas.
          </p>
        </section>
        <section className="section is-small">
          <div
            className="columns is-multiline"
            style={{marginBottom: '1.5rem'}}
          >
            {products.map(product => (
              <div
                className="column is-one-third"
                key={product.slug}
                style={{marginBottom: '2rem'}}
              >
                <Link to={`${product.url}${affiliateQuery}`}>
                  <div
                    style={{
                      minHeight: 350,
                      border: '1px solid #ddd',
                      borderRadius: '8px',
                      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                      transition: 'transform 0.2s ease-in-out',
                    }}
                    className="card"
                    onMouseEnter={e =>
                      (e.currentTarget.style.transform = 'scale(1.05)')
                    }
                    onMouseLeave={e =>
                      (e.currentTarget.style.transform = 'scale(1)')
                    }
                  >
                    {/* Product Title */}
                    <h3
                      style={{
                        height: 45,
                        padding: '1rem 0',
                        backgroundColor: '#f7f7f7',
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px',
                        textAlign: 'center',
                        fontWeight: '600',
                        color: '#333',
                      }}
                      className="title is-5"
                    >
                      {product?.title}
                    </h3>

                    {/* Product Image */}
                    <div className="card-image">
                      <figure className="image" style={{textAlign: 'center'}}>
                        <img
                          src={`/assets/products/${product.fileName}`}
                          alt={product.fileName}
                          style={{maxHeight: '200px', objectFit: 'contain'}}
                        />
                      </figure>
                    </div>

                    {/* Product Description */}
                    <div className="card-content">
                      <div className="has-text-centered">
                        <p style={{fontSize: '14px', color: '#555'}}>
                          {product.description}
                        </p>

                        {/* Add Ratings (Example) */}
                        <div style={{margin: '10px 0'}}>
                          <span role="img" aria-label="star">
                            ⭐
                          </span>
                          <span role="img" aria-label="star">
                            ⭐
                          </span>
                          <span role="img" aria-label="star">
                            ⭐
                          </span>
                          <span role="img" aria-label="star">
                            ⭐
                          </span>
                          <span role="img" aria-label="half-star">
                            ⭐️
                          </span>
                        </div>

                        {/* Add Urgency */}
                        {/* <p
                          className="has-text-danger"
                          style={{fontWeight: 'bold'}}
                        >
                          ¡Oferta por tiempo limitado!
                        </p> */}

                        {/* Call-to-Action */}
                        <Link
                          to={`${product.url}${affiliateQuery}`}
                          className="button is-primary is-fullwidth"
                          style={{
                            marginTop: '10px',
                            backgroundColor: '#3273dc',
                            color: 'white',
                          }}
                        >
                          Compra Ahora
                        </Link>
                      </div>
                    </div>

                    {/* Trust Signal */}
                    <div
                      style={{
                        padding: '10px',
                        backgroundColor: '#f1f1f1',
                        borderBottomLeftRadius: '8px',
                        borderBottomRightRadius: '8px',
                        textAlign: 'center',
                        fontSize: '12px',
                        color: '#777',
                      }}
                    >
                      <span role="img" aria-label="shield">
                        🛡️
                      </span>{' '}
                      Compra Segura y Garantizada
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </section>

        {/* Sticky Offer Modal or Banner */}
        {/* <div
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            zIndex: '1000',
            backgroundColor: '#fff',
            border: '1px solid #ddd',
            borderRadius: '8px',
            padding: '15px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          }}
        >
          <p className="has-text-centered">
            ¡Obtén un <strong>20% de descuento</strong> en tu primer pedido!
          </p>
          <Link
            to="https://example.com/discount-link"
            className="button is-success is-fullwidth"
            style={{marginTop: '10px'}}
          >
            Obtener Descuento
          </Link>
        </div> */}
      </div>
    </Layout>
  )
}
